<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ $lang.labels.account }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">{{ $lang.labels.yourAccount }}</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-text-field v-model="formData.email" label="Email" outlined @keyup.enter="submit()"></v-text-field>
        <v-text-field
          v-model="formData.captchaAnswer"
          label="Captcha"
          outlined
          hide-details
          @keyup.enter="submit()"
        ></v-text-field>
        <div style="position: relative">
          <v-img :src="`data:image/png;base64, ${captchaBE.image}`" class="mt-3"></v-img>
          <v-btn
            icon
            light
            color="primary"
            text
            :disabled="loadingCaptcha"
            large
            absolute
            style="bottom: 2px; right: 2px;"
            @click="getCaptcha()"
          >
            <v-icon
              dense
              smal
            >mdi-refresh
            </v-icon>
          </v-btn>
        </div>
        <v-btn
          block
          class="accent mt-4 color-accent-text"
          x-large
          :disabled="loadingLogin || !formData.email || !formData.captchaAnswer"
          :loading="loadingLogin"
          @click="submit()"
        >Reset</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import {
  forgotPasswordUsingPOST as forgotPassword,
  captchaCreateUsingGET as captchaCreate
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      loadingCaptcha: false,
      loadingLogin: false,
      formData: {
        email: '',
        captchaAnswer: '',
        captchaKey: ''
      },
      captchaBE: {
        key: '',
        image: ''
      },
      err: '',
      success: ''
    }
  },
  created() {
    this.getCaptcha()
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    submit() {
      this.loadingLogin = true
      // Check if email/Password are empty
      if (this.formData.email.length === 0) {
        this.addSnackbar({
          message: this.$lang.errors.missingEmail,
          timeout: 5000,
          color: 'error'
        })
        this.loadingLogin = false

        return
      }
      if (this.formData.captchaAnswer.length === 0) {
        this.addSnackbar({
          message: this.$lang.errors.missingCaptcha,
          timeout: 5000,
          color: 'error'
        })
        this.loadingLogin = false

        return
      }
      this.formData.email = this.formData.email.toLowerCase().trim()
      // Authenticate user
      forgotPassword({ body: this.formData })
        .then((res) => {
          if (res?.data?.status === 'SUCCESS') {

            this.loadingLogin = false
            this.addSnackbar({
              message: this.$lang.success.passwordReset,
              timeout: 5000,
              color: 'success'
            })
            setTimeout(() => {
              window.open('/login','_self')
            }, 2000)

            return
          }
          this.loadingLogin = false
          const errorMessage = res?.response?.data?.statusText ? res.response.data.statusText : this.$lang.errors.somethingWentWrong

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
        })
        .catch((err) => {
          console.log(err)
          this.addSnackbar({
            message: this.$lang.errors.somethingWentWrong,
            timeout: 5000,
            color: 'error'
          })
          this.loadingLogin = false
        })

    },
    getCaptcha() {
      this.loadingCaptcha = true
      captchaCreate()
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.captchaBE = res.data.data
            this.formData.captchaKey = res.data.data.key
            this.loadingCaptcha = false

            return
          }
          this.addSnackbar({
            message: res.data.statusText,
            timeout: 5000,
            color: 'error'
          })
          this.loadingCaptcha = false
        })
        .catch((err) => {
          console.log(err)
          this.addSnackbar({
            message: this.$lang.errors.somethingWentWrong,
            timeout: 5000,
            color: 'error'
          })
          this.loadingCaptcha = false
        })
    }
  }
}
</script>
